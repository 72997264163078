import { Button } from '@/shared/ui/button';
import { ButtonTextWithoutLink } from '@/shared/ui/button/ButtonTextWithoutLink';
import { Input } from '@/shared/ui/input';
import { useContactUsMutation, useLayout } from '@/store/api';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useState, Fragment, useRef, useEffect, ChangeEvent } from 'react';
import { useForm, useWatch } from 'react-hook-form';

type FormValues = {
  email: string;
  name: string;
  description: string;
  phone?: string;
  file?: any;
};

export const ModalContactForm = ({
  classNames,
  items,
  buttonText,
  policyWidth,
  setModalOpen,
  setSubmitted,
}: {
  classNames?: {
    form?: string;
    container?: string;
    tabs?: string;
    inputs?: [string, string, string];
  };
  items?: string[];
  buttonText?: string;
  policyWidth?: string;
  setModalOpen: (arg: boolean) => void;
  setSubmitted: (arg: boolean) => void;
}) => {
  const { forms } = useLayout();
  const { t } = useTranslation('common');

  const [activeTab, setActiveTab] = useState<number | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setError,
    control,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const [submit, { isLoading }] = useContactUsMutation();

  const onSubmit = async (data: FormValues) => {
    try {
      await submit({
        ...data,
        interest: 'Вопрос с сайта',
      });
    } catch (e) {
      console.log(e);
    } finally {
      reset();
      setModalOpen(false);
      setSubmitted(true);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn(
        classNames?.form,
        'tb:max-w-[467px] tb:gap-[22px] mb:max-tb:mb-[1.65rem] mb:max-tb:gap-[1.3rem] flex flex-col',
      )}
    >
      <Input
        {...register('name', {
          required: t('forms.errors.name.required') ?? 'Имя обязательно',
          validate: {
            maxLength: (v) =>
              v.length <= 255 ||
              (t('forms.errors.name.maxLength') ??
                'Maximum number of characters 255'),
          },
        })}
        className={classNames?.inputs?.[0]}
        label={forms?.contact_us?.name ?? ''}
        errorMessage={errors.name?.message}
      />
      <Input
        {...register('email', {
          required: t('forms.errors.email.required') ?? 'Email обязателен',
          validate: {
            maxLength: (v) =>
              v.length <= 255 ||
              (t('forms.errors.email.maxLength') ??
                'Maximum number of characters 255'),
            matchPattern: (v) =>
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v) ||
              (t('forms.errors.email.matchPattern') ??
                'Email должен быть действительным'),
          },
        })}
        errorMessage={errors.email?.message}
        className={classNames?.inputs?.[1]}
        label={forms?.contact_us?.email ?? ''}
      />
      <Input
        {...register('description', {
          required:
            t('forms.errors.description.required') ?? 'Вопрос обязателен',
          validate: {
            maxLength: (v) =>
              v.length <= 4096 ||
              (t('forms.errors.question.maxLength') ??
                'Maximum number of characters 4096'),
          },
        })}
        errorMessage={errors.description?.message}
        className={classNames?.inputs?.[2]}
        label={forms?.contact_us?.question ?? 'Какой у вас вопрос*'}
      />
      <ButtonTextWithoutLink
        className="tb:mt-[17px] mb:mt-[20px] disabled:text-gray-3"
        type="submit"
        withArrow
        isCapsVariant
        disabled={!isValid}
      >
        {forms?.contact_us?.button_text_3}
      </ButtonTextWithoutLink>
    </form>
  );
};
