import { Typography } from '../typography';
import cn from 'classnames';
import {
  MutableRefObject,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CountryData, default as Input } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  errorMessage?: string;
  supportText?: string;
  submited: boolean;
  valid: boolean;
  setValid: (arg: boolean) => void;
  phoneNumber: string;
  setPhoneNumber: (arg: string) => void;
}

export const PhoneInput = forwardRef<any, Props>(function ForwardInput(
  {
    label,
    errorMessage,
    supportText,
    id,
    disabled,
    className,
    placeholder,
    submited,
    valid,
    setValid,
    phoneNumber,
    setPhoneNumber,
    ...other
  },
  ref,
) {
  //   const [phoneNumber, setPhoneNumber] = useState(''); // Этот стейт должен будет храниться в самой форме и пропсами передаваться сюда
  const [text, setText] = useState(placeholder);
  const numberRef = useRef(0);

  useEffect(() => {
    if (
      phoneNumber.length !== 0 &&
      (phoneNumber.length === numberRef.current ||
        (numberRef.current > 15 && phoneNumber.length === 15))
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [phoneNumber, setValid]);
  return (
    <div
      className={cn(
        'form-group w-full pt-2',
        {
          'pointer-events-none': disabled,
        },
        className,
      )}
    >
      <Input
        value={phoneNumber}
        onChange={(phone, country) => {
          setPhoneNumber(phone);
          if (phone.length > 0 && Object.keys(country).length > 0) {
            numberRef.current = (country as CountryData)?.format
              .split('')
              .filter((item) => item === '.').length;
          }
        }}
        buttonClass="hidden"
        containerClass={cn(
          { container: phoneNumber?.length > 0 },
          {
            errorContainer:
              submited &&
              !valid &&
              !(
                (phoneNumber.length === numberRef.current &&
                  numberRef.current !== 0) ||
                phoneNumber.length === 15
              ),
          },
        )}
        inputClass={cn(
          'form__field !w-full !pl-0 pt-2.5 pb-2.5 !text-textS hover:!border-green focus:!border-green focus:!pb-2.5',
          { '!border-gray-4 !text-gray-4': disabled },
          { errorInput: submited && !valid },
        )}
        onFocus={() => setText(label)}
        onBlur={() => (phoneNumber?.length === 0 ? setText(placeholder) : '')}
        specialLabel={text}
        inputProps={{
          name: 'phone',
        }}
      />
      {submited &&
        !valid &&
        !(
          (phoneNumber.length === numberRef.current &&
            numberRef.current !== 0) ||
          phoneNumber.length === 15
        ) && (
          <Typography
            variant="textS"
            className={cn(
              'mt-1',
              {
                'text-gray-3': !disabled,
              },
              {
                'text-gray-4': disabled,
              },
              { 'text-red': !valid },
            )}
          >
            {phoneNumber.length !== 0 ? (
              <>Номер телефона указан некорректно</>
            ) : (
              <>Поле обязательно для заполнения</>
            )}
          </Typography>
        )}
    </div>
  );
});
