import { ButtonTextWithoutLink } from '@/shared/ui/button/ButtonTextWithoutLink';
import { Modal } from '@/shared/ui/modal';
import { Snackbar } from '@/shared/ui/snackbar';
import { useLayout } from '@/store/api';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { Navbar } from '../../shared/ui/navbar';
import { Typography } from '../../shared/ui/typography';
import { ModalContactForm } from '../contactForm/ModalContactForm';
import { LanguageSwitcher } from '../languageSwitcher';

export const MobileFooter = ({ className }: { className: string }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { footer, phone, email, legalAddress } = useLayout();

  return (
    <>
      <footer
        className={`${className} bg-blue-additional tb:hidden block pb-4 pt-[24px]`}
      >
        <div className="container">
          <div className="mb-[47px] flex items-end justify-between">
            <Link href="/">
              <Image
                src="/images/LogoWhite.svg"
                width={180}
                height={36}
                alt="footer-logo"
              />
            </Link>
            <div className="mb:mr-[4px] mb:max-tb:mb-[-5px]">
              <LanguageSwitcher isShowBoth />
            </div>
          </div>
          <div className="mb:ml-[2px] mb-8 flex gap-14">
            <div className="flex-auto-0 w-[115px]">
              <Navbar
                classNames={{
                  list: 'flex flex-col gap-[0.9rem]',
                  listItem: 'text-white/40 hover:text-white active:text-white',
                }}
                fileLinks={[footer?.privacy_policy, footer?.user_agreement]}
                activeColor="white"
              />
            </div>
            <div className="flex flex-col gap-[1.1rem]">
              <div className="flex flex-col gap-y-[6px]">
                <Typography
                  variant="textButton"
                  className="font-normal text-white"
                >
                  {footer?.contact?.phone}
                </Typography>
                <a href={phone?.href ?? ''}>
                  <Typography variant="textS" className="text-white/40">
                    {phone?.title}
                  </Typography>
                </a>
              </div>
              <div className="flex flex-col gap-y-1.5">
                <Typography
                  variant="textButton"
                  className=" font-normal text-white"
                >
                  {footer?.contact?.email}
                </Typography>
                <a href={email?.href ?? ''}>
                  <Typography variant="textS" className="text-white/40">
                    {email?.title}
                  </Typography>
                </a>
              </div>
              <div className="flex flex-col gap-y-2">
                <Typography
                  variant="textButton"
                  className=" font-normal text-white"
                >
                  {footer?.contact?.address}
                </Typography>
                <address className="not-italic">
                  <Typography
                    variant="textS"
                    className="mb:static tb:absolute pr-2 text-white/40"
                  >
                    {legalAddress?.address}
                  </Typography>
                </address>
              </div>
              <ButtonTextWithoutLink
                onClick={() => setModalOpen(true)}
                isCapsVariant
                className="mt-[-3px] flex cursor-pointer select-none font-normal uppercase text-white active:text-white/80"
              >
                {footer?.contact?.title}
              </ButtonTextWithoutLink>
              {/* <Typography
                variant="textButtonCaps"
                onClick={() => setModalOpen(true)}
                className="mt-[-3px] flex cursor-pointer select-none font-normal uppercase text-white"
              >
                {t('footer.contacts.title')}
              </Typography> */}
            </div>
          </div>
          <Typography variant="caption12" className="text-white/40">
            {footer?.copyright}
          </Typography>
        </div>
      </footer>
      {modalOpen && (
        <Modal
          title={`Связаться с распределительным центром Москва`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        >
          <ModalContactForm
            setSubmitted={setSubmitted}
            setModalOpen={setModalOpen}
          />
        </Modal>
      )}
      <Snackbar setSubmitted={setSubmitted} submitted={submitted} />
    </>
  );
};
