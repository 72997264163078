import Arrow from '@/assets/arrow.svg';
import Link from 'next/link';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  mode?: 'dark' | 'light';
  withArrow?: boolean;
  withTopArrow?: boolean;
  isCapsVariant?: boolean;
  href?: string | object;
  onClick?: () => void;
  locale?: string | false | undefined;
}

const defaultStyles = {
  dark: 'text-black hover:text-dark active:text-gray-3',
  light: 'text-white hover:text-white/80 active:text-gray-3',
};

const defaultDisabledStyles = {
  dark: 'pointer-events-none',
  light: 'pointer-events-none',
};

export const ButtonText = ({
  children,
  disabled = false,
  className,
  mode = 'dark',
  withArrow = false,
  withTopArrow = false,
  isCapsVariant = false,
  href,
  onClick,
  locale,
  ...other
}: Props) => {
  return (
    <Link
      href={href || ''}
      locale={locale}
      // disabled={disabled}
      // type="button" // validator - error
      className={`${defaultStyles[mode]} ${
        disabled && defaultDisabledStyles[mode]
      } flex items-center ${
        isCapsVariant ? 'text-textButtonCaps uppercase' : 'text-textButton'
      } ${className}`}
      onClick={onClick}
      // {...other}
    >
      {children}
      {withArrow && (
        <div className="pb-[4px]">
          <svg
            className="ml-[13px]"
            width="7"
            height="12"
            fill="currentColor"
            viewBox="0 0 7 12"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.04547 2.16556e-07L-2.50171e-07 1.0522L4.91055 6.01689L0.0314551 10.9535L1.0713 12L7 6.015L1.04547 2.16556e-07Z"
            />
          </svg>
        </div>
      )}

      {withTopArrow && (
        <div className="pb-[4px]">
          <svg
            className="mt-[1px] ml-[3px]"
            width="28"
            height="32"
            viewBox="0 0 28 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19 9L19 19L17.4615 19L17.4615 11.6263L10.1705 18.9173L9.08264 17.8295L16.3737 10.5385L9 10.5385L9 9L19 9Z"
              fill="#27282C"
            />
          </svg>
        </div>
      )}
    </Link>
  );
};
