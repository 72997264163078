import { useRouter } from 'next/router';
import { Services, StrapiQuery, StrapiResponse } from '~shared';
import { api } from './api';

export const servicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    services: builder.query<Services, StrapiQuery | undefined>({
      query: (params) => ({
        url: '/services',
        params,
      }),
      transformResponse: ({ data }: StrapiResponse<Services>) => data,
    }),
  }),
});

export const { useServicesQuery } = servicesApi;

export const useServices = () => {
  const { locale, isFallback } = useRouter();
  return useServicesQuery(
    { locale },
    { skip: isFallback, selectFromResult: ({ data }) => ({ ...data }) },
  );
};
