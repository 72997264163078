import { useRouter } from 'next/router';
import { Contacts, StrapiQuery, StrapiResponse } from '~shared';
import { api } from './api';

export const contactsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    contacts: builder.query<Contacts, StrapiQuery | undefined>({
      query: (params) => ({
        url: '/contacts',
        params,
      }),
      transformResponse: ({ data }: StrapiResponse<Contacts>) => data,
    }),
  }),
});

export const { useContactsQuery } = contactsApi;

export const useContacts = () => {
  const { locale, isFallback } = useRouter();
  return useContactsQuery(
    { locale },
    { skip: isFallback, selectFromResult: ({ data }) => ({ ...data }) },
  );
};
