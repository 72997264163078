export const useControlStyleByModal = () => {
  const setStylesWhenOpen = () => {
    // создаст элемент с прокруткой
    const div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';

    // мы должны вставить элемент в документ, иначе размеры будут равны 0
    document.body.append(div);
    const scrollWidth = div.offsetWidth - div.clientWidth;

    div.remove();

    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollWidth}px`;
  };

  const setStylesWhenClose = () => {
    document.body.style.overflow = 'unset';
    document.body.style.paddingRight = 'unset';
  };

  return {
    setStylesWhenOpen,
    setStylesWhenClose,
  };
};
