import { useSearchQuery } from '@/store/api/search';
import React, { useState, useEffect, useRef } from 'react';
import SearchIcon from '@/assets/search.svg';
import { useBreakpoint } from '@/shared/hooks/useBreakpoints';
import styles from './Search.module.css';

interface SearchProps {}

const Search = ({}: SearchProps): JSX.Element => {
  const { isMobile } = useBreakpoint();
  const [term, setTerm] = useState<string | undefined>();
  const [isActive, setActive] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { data, isFetching } = useSearchQuery({ term });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (searchRef.current && !searchRef.current.contains(e.target as Node)) {
      setActive(false);
      setTerm('');
    }
  };

  const handleClear = () => setTerm('');

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleClickOutside);
      inputRef.current && inputRef.current.focus();
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);

  const hasResult = data && data?.data?.length > 0;

  return (
    <div
      ref={searchRef}
      className={`flex items-center ${
        isMobile && isActive
          ? 'absolute left-0 top-0 h-14 w-full bg-white'
          : 'relative'
      }`}
    >
      <SearchIcon
        className="mr-2"
        onClick={() => {
          setActive(!isActive);
        }}
      />
      <div
        className={`text-textS absolute ${
          isActive
            ? `${styles.SearchContainer} ${isMobile ? 'w-full' : 'w-64'}`
            : 'w-0 overflow-hidden'
        } ${isMobile ? '' : 'transition-width duration-300 ease-in-out'}`}
        style={{
          left: isMobile ? 0 : 'calc(19px + 0.5rem)',
        }}
      >
        <div
          className="flex items-center"
          style={{
            borderBottom: '1px solid #6DBF5A',
          }}
        >
          {isMobile && (
            <SearchIcon
              className="mr-2"
              onClick={() => {
                setActive(!isActive);
              }}
            />
          )}
          <input
            type="text"
            onChange={handleInputChange}
            className={`py-2 text-sm outline-none ${
              isMobile ? 'w-full' : 'w-64'
            }`}
            ref={inputRef}
            value={term}
          ></input>
          {term && term.length && <button onClick={handleClear}>X</button>}
        </div>
        {hasResult && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1200,
            }}
            className={`bg-white px-6 py-4 shadow-lg ${
              isMobile ? 'w-full' : 'w-64'
            }`}
          >
            {data?.data.map(({ href, keyword, relevance, title }) => {
              const regex = new RegExp(`(${term})`, 'gi');
              const parts = keyword.split(regex);

              return (
                <div key={keyword} className="py-2">
                  <a href={href}>
                    <p className="font-bold">{title}</p>
                    <p>
                      {parts.map((part, index) =>
                        regex.test(part) ? (
                          <span key={index} style={{ color: '#26A570' }}>
                            {part}
                          </span>
                        ) : (
                          <span key={index}>{part}</span>
                        ),
                      )}
                    </p>
                  </a>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
