import { navLinks } from '@/shared/constants';
import { useLayout } from '@/store/api';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { SharedFile } from '~shared';
import { Variant } from '../typography/Typography';

interface Props {
  classNames?: {
    navbar?: string;
    list?: string;
    listItem?: string;
  };
  fileLinks?: (SharedFile | null | undefined)[];
  insteadLinks?: {
    translationKey: string;
    path: string;
  }[];
  variant?: Variant;
  onClick?: () => void;
  activeColor: string;
}

export const Navbar = ({
  classNames,
  fileLinks = [],
  insteadLinks,
  onClick,
  variant = 'textButton',
  activeColor,
}: Props) => {
  const { locale, asPath } = useRouter();
  const { menu } = useLayout();

  return (
    <nav className={cn(classNames?.navbar, classNames?.list)}>
      {navLinks.map((item) => (
        <Link
          className={cn(classNames?.listItem, `text text-${variant}`)}
          key={item.translationKey}
          href={item.path}
          locale={locale}
          onClick={onClick}
          style={`/${item.path}` === asPath ? { color: activeColor } : {}}
        >
          {menu && menu[item.translationKey as keyof typeof menu]}
        </Link>
      ))}
      {fileLinks.map((item) => (
        <Link
          className={cn(classNames?.listItem, `text text-${variant}`)}
          key={item?.id}
          href={item?.file?.url ?? ''}
          locale={locale}
          onClick={onClick}
          target="_blank"
          download
        >
          {item?.title}
        </Link>
      ))}
    </nav>
  );
};
