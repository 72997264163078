import { useLayout } from '@/store/api';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';

interface Props {
  className?: string;
  phone?: string;
}

export const Phone = forwardRef<any, Props>(function ForwardPhone(
  { className, phone },
  ref,
) {
  const { phone: fallbackPhone } = useLayout();
  return (
    <a
      ref={ref}
      href={
        phone ? `tel:${phone.replace(/\s/g, '')}` : fallbackPhone?.href ?? ''
      }
      className={cn(className, 'text-gray-1 hover:text-black')}
    >
      {phone ?? fallbackPhone?.title}
    </a>
  );
});

export const MPhone = motion(Phone);
