import { About, StrapiQuery, StrapiResponse } from '~shared';
import { api } from './api';
import { useRouter } from 'next/router';

export const aboutApi = api.injectEndpoints({
  endpoints: (builder) => ({
    about: builder.query<About, StrapiQuery | undefined>({
      query: (params) => ({
        url: '/about',
        params,
      }),
      transformResponse: ({ data }: StrapiResponse<About>) => data,
    }),
  }),
});

export const { useAboutQuery } = aboutApi;

export const useAbout = () => {
  const { locale, isFallback } = useRouter();
  return useAboutQuery(
    { locale },
    { skip: isFallback, selectFromResult: ({ data }) => ({ ...data }) },
  );
};
