import { useRouter } from 'next/router';
import { Career, Main, StrapiQuery, StrapiResponse } from '~shared';
import { api } from './api';

export const careerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    career: builder.query<Career, StrapiQuery | undefined>({
      query: (params) => ({
        url: '/career',
        params,
      }),
      transformResponse: ({ data }: StrapiResponse<Career>) => data,
    }),
    cv: builder.mutation<any, { [key: string]: any }>({
      query: ({ file, ...entries }) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(entries));

        if (file) {
          formData.append('files.resume', file);
        }

        return {
          url: '/cvs',
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const { useCareerQuery, useCvMutation } = careerApi;

export const useCareer = () => {
  const { locale, isFallback } = useRouter();
  return useCareerQuery(
    { locale },
    { skip: isFallback, selectFromResult: ({ data }) => ({ ...data }) },
  );
};
