type Variant = 'filled' | 'outlined';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: Variant;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  isCapsVariant?: boolean;
}

const defaultStyles: Record<Variant, string> = {
  filled: 'text-white bg-black hover:bg-dark active:bg-gray-1',
  outlined:
    'text-black border border-black hover:bg-dark hover:text-white active:bg-gray-1',
};

const defaultDisabledStyles = {
  filled: 'text-white/80 bg-gray-3 pointer-events-none',
  outlined: 'text-gray-3 border-gray-3 pointer-events-none',
};

export const Button = ({
  variant,
  children,
  className,
  disabled = false,
  isCapsVariant = false,
  ...other
}: Props) => {
  return (
    <button
      disabled={disabled}
      className={`${defaultStyles[variant]} ${
        disabled && defaultDisabledStyles[variant]
      } pb-2 pt-3 max-h-[44px] transition-all duration-300 mb:w-full tb:w-fit tb:px-[40px] ${
        isCapsVariant ? 'text-textButtonCaps' : 'text-textButton'
      } ${className}`}
      {...other}
    >
      {children}
    </button>
  );
};
