import { Main, StrapiQuery, StrapiResponse } from '~shared';
import { api } from './api';
import { useRouter } from 'next/router';

export const mainApi = api.injectEndpoints({
  endpoints: (builder) => ({
    main: builder.query<Main, StrapiQuery | undefined>({
      query: (params) => ({
        url: '/main',
        params,
      }),
      transformResponse: ({ data }: StrapiResponse<Main>) => data,
    }),
  }),
});

export const { useMainQuery } = mainApi;

export const useMain = () => {
  const { locale, isFallback } = useRouter();
  return useMainQuery(
    { locale },
    { skip: isFallback, selectFromResult: ({ data }) => ({ ...data }) },
  );
};
