export const navLinks = [
  { translationKey: 'home', path: '/' },
  { translationKey: 'about', path: 'about-us' },
  { translationKey: 'services', path: 'services' },
  { translationKey: 'careers', path: 'careers' },
  { translationKey: 'contacts', path: 'contacts' },
];

export const footerNavLinks = [
  { translationKey: 'home', path: '/' },
  { translationKey: 'services', path: 'services' },
  { translationKey: 'contacts', path: 'contacts' },
  { translationKey: 'about', path: 'about-us' },
  { translationKey: 'careers', path: 'careers' },
];
