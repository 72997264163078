import { api } from './api';

export const formsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    contactCenter: builder.mutation<boolean, { [key: string]: any }>({
      query: ({ ...data }) => ({
        url: '/forms/center',
        method: 'POST',
        body: {
          data,
        },
      }),
    }),
    contactUs: builder.mutation<boolean, { [key: string]: any }>({
      query: ({ ...data }) => ({
        url: '/forms/us',
        method: 'POST',
        body: {
          data,
        },
      }),
    }),
    internship: builder.mutation<boolean, { [key: string]: any }>({
      query: ({ ...data }) => ({
        url: '/forms/internship',
        method: 'POST',
        body: {
          data,
        },
      }),
    }),
  }),
});

export const {
  useContactCenterMutation,
  useContactUsMutation,
  useInternshipMutation,
} = formsApi;
