import { Typography } from '../typography';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { forwardRef, useEffect, useRef, useState } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  errorMessage?: string;
  supportText?: string;
  options: (string | string[] | { value: string; label: string })[];
  value: string;
  setValue: (arg: string) => void;
  cityOptions?: boolean;
}

export const Dropdown = forwardRef<any, Props>(function ForwardInput(
  {
    label,
    errorMessage,
    supportText,
    id,
    disabled,
    className,
    options,
    value,
    setValue,
    cityOptions,
    ...other
  },
  ref,
) {
  const [isOpened, setIsOpened] = useState(false);
  const clickedRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    const closeOpenMenus = (e: MouseEvent) => {
      if (
        clickedRef.current &&
        isOpened &&
        !clickedRef.current.contains(e.target as Node)
      ) {
        setIsOpened(false);
      }
    };
    document.addEventListener('mousedown', closeOpenMenus);

    return () => {
      document.removeEventListener('mousedown', closeOpenMenus);
    };
  }, [isOpened]);

  return (
    <div
      ref={clickedRef}
      onClick={() => setIsOpened((prev) => !prev)}
      className={cn(
        'form-group relative w-full pt-2',
        {
          'pointer-events-none': disabled,
        },
        className,
      )}
    >
      <input
        className={cn(
          'form__field text-gray-1 border-gray-1 text-textS hover:border-green focus:border-green w-full cursor-pointer rounded-none border-b pb-2.5 pt-2.5 focus:pb-2.5',
          { 'border-gray-4 text-gray-4': disabled },
          { '!border-red': !!errorMessage && value.length === 0 },
        )}
        placeholder={label}
        id={id}
        ref={ref}
        value={cityOptions ? (value ? (t(value) as string) : '') : value}
        readOnly
        {...other}
      ></input>
      <svg
        className={cn(
          'pointer-events-none absolute right-0 top-[17px]',
          { 'rotate-180': isOpened },
          { 'rotate-0': !isOpened },
        )}
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0003 14.6667L6.41699 8.25L15.5837 8.25L11.0003 14.6667Z"
          fill="#484A4E"
        />
      </svg>
      <>
        {isOpened && (
          <div className="dropdown absolute flex max-h-[352px] w-[100%] flex-col gap-y-[16px] overflow-auto px-[24px] py-[22px]">
            {options.map((item, index) => {
              return (
                <>
                  {!Array.isArray(item) ? (
                    <Typography
                      variant="textButton"
                      key={typeof item === 'object' ? item?.value : item}
                      className={cn(
                        'text-gray-2 hover:text-gray-1 cursor-pointer transition-all duration-150',
                        {
                          '!text-dark': value === item,
                        },
                      )}
                      onClick={() => {
                        setValue(typeof item === 'object' ? item?.label : item);
                      }}
                    >
                      {t(`${typeof item === 'object' ? item?.label : item}`)}
                    </Typography>
                  ) : (
                    <>
                      {' '}
                      {item.map((city) => (
                        <Typography
                          variant="textButton"
                          key={city}
                          className={cn(
                            'text-gray-2 hover:text-gray-1 cursor-pointer transition-all duration-150',
                            {
                              '!text-dark': value === city,
                            },
                          )}
                          onClick={() => {
                            setValue(city);
                          }}
                        >
                          {t(`${city}`)}
                        </Typography>
                      ))}
                    </>
                  )}
                </>
              );
            })}
          </div>
        )}
      </>
      {label && (
        <label
          className={cn(
            'form__label pointer-events-none w-[100%]',
            { 'border-gray-4 !text-gray-4': disabled },
            { '!text-red': !!errorMessage && value.length === 0 },
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {(supportText || errorMessage) && value.length === 0 && (
        <Typography
          variant="textS"
          className={cn(
            'mt-1',
            {
              'text-gray-3': !disabled,
            },
            {
              'text-gray-4': disabled,
            },
            { 'text-red': !!errorMessage },
          )}
        >
          {errorMessage || supportText}
        </Typography>
      )}
    </div>
  );
});
