import Arrow from '@/assets/arrow.svg';
import Link from 'next/link';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  mode?: 'dark' | 'light';
  withArrow?: boolean;
  isCapsVariant?: boolean;
  href?: string | object;
  onClick?: () => void;
  locale?: string | false | undefined;
}

const defaultStyles = {
  dark: 'text-black hover:text-dark active:text-gray-3',
  light: 'text-white hover:text-white/80 active:text-gray-3',
};

const defaultDisabledStyles = {
  dark: 'pointer-events-none',
  light: 'pointer-events-none',
};

export const ButtonTextWithoutLink = ({
  children,
  disabled = false,
  className,
  mode = 'dark',
  withArrow = false,
  isCapsVariant = false,
  onClick,
  ...other
}: Props) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`${defaultStyles[mode]} ${
        disabled && defaultDisabledStyles[mode]
      } flex items-center ${
        isCapsVariant ? 'text-textButtonCaps' : 'text-textButton'
      } ${className}`}
      onClick={onClick}
      {...other}
    >
      {children}
      {withArrow && (
        <div className="pb-[4px]">
          <svg
            className="ml-[13px]"
            width="7"
            height="12"
            fill="currentColor"
            viewBox="0 0 7 12"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.04547 2.16556e-07L-2.50171e-07 1.0522L4.91055 6.01689L0.0314551 10.9535L1.0713 12L7 6.015L1.04547 2.16556e-07Z"
            />
          </svg>
        </div>
      )}
    </button>
  );
};
