import { footerNavLinks } from '@/shared/constants/navLinks';
import { ButtonTextWithoutLink } from '@/shared/ui/button/ButtonTextWithoutLink';
import { Modal } from '@/shared/ui/modal';
import { Snackbar } from '@/shared/ui/snackbar';
import { useLayout } from '@/store/api';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Typography } from '../../shared/ui/typography';
import { ModalContactForm } from '../contactForm/ModalContactForm';
import { LanguageSwitcher } from '../languageSwitcher';
import { MobileFooter } from './MobileFooter';

interface Props {
  className: string;
}

export const Footer = ({ className }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { locale, asPath } = useRouter();
  const { menu, footer, phone, email, legalAddress } = useLayout();

  return (
    <>
      <MobileFooter className={className} />
      <footer
        className={`${className} bg-blue-additional tb:pt-[38px] dk:pt-[64px] tb:pb-[27px] dk:pb-[52px] tb:block hidden pb-14`}
      >
        <div className="container">
          <div className="flex items-start">
            <Link
              href={'/'}
              className="flex-auto-0 dk:w-[13.188rem] tb:w-[18.15rem] dk:mr-[15.375rem]"
            >
              <Image
                src="/images/LogoWhite.svg"
                width={211}
                height={42}
                alt="footer-logo"
              />
            </Link>
            <div className="dk:mr-[0rem] dk:w-[35rem] tb:w-[28rem]">
              <nav className="dk:justify-start dk:gap-y-1.5 dk:gap-x-[48px] tb:gap-y-1.5 dk:mt-[-4px] tb:mt-[-5px] flex flex-wrap">
                {footerNavLinks.map((item, index) => (
                  <div
                    key={item.translationKey}
                    className={`${
                      index === 1
                        ? 'tb:w-[8.5rem] dk:w-3/12'
                        : 'tb:max-dk:w-[34%] w-3/12'
                    } ${index === 5 && 'dk:mr-auto'} ${
                      index === 2 && 'dk:ml-[7px] tb:max-dk:ml-[-2px]'
                    }`}
                  >
                    <Link
                      href={item.path}
                      locale={locale}
                      className={`text text-textButton text-white/40 hover:text-white active:text-white `}
                      style={
                        `/${item.path}` === asPath ? { color: 'white' } : {}
                      }
                    >
                      {menu && menu[item.translationKey as keyof typeof menu]}
                    </Link>
                  </div>
                ))}
              </nav>
            </div>
            <div className=" tb:mt-[-4px] ml-auto mr-[4px]">
              {/* <LanguageSwitcher isShowBoth /> */}
            </div>
          </div>
          <div className="mt-[15px] flex flex-col gap-2">
            <Typography variant="textButton" className="text-white/40 ">
              <a
                className="hover:text-white active:text-white"
                href={`${process.env.NEXT_PUBLIC_API_URL}${footer?.privacy_policy?.file?.url}`}
                target="_blank"
                download
              >
                {footer?.privacy_policy?.title}
              </a>
            </Typography> 
            <Typography variant="textButton" className="text-white/40 ">
              <a
                className="hover:text-white active:text-white"
                href={`${process.env.NEXT_PUBLIC_API_URL}${footer?.user_agreement?.file?.url}`}
                target="_blank"
                download
              >
                {footer?.user_agreement?.title}
              </a>
            </Typography>
          </div>
          <div className="dk:mt-[91px] tb:mt-[86px]  flex">
            <div className="dk:w-[28.5rem] tb:w-[18.15rem] flex-auto-0 flex flex-col gap-y-2.5">
              {/* <Typography
                variant="textButtonCaps"
                onClick={() => setModalOpen(true)}
                className="dk:max-w-[30%] tb:max-w-[42%] dk:my-[2px] flex cursor-pointer select-none font-normal uppercase text-white"
              >
                
              </Typography> */}
              <ButtonTextWithoutLink
                onClick={() => setModalOpen(true)}
                isCapsVariant
                className="btn__no-link dk:max-w-[30%] tb:max-w-[42%] dk:my-[2px] flex cursor-pointer select-none font-normal uppercase text-white hover:text-white/80"
              >
                {footer?.modal?.button}
              </ButtonTextWithoutLink>
              <Typography variant="caption12" className="text-white/40">
                {footer?.copyright}
              </Typography>
            </div>
            <div className="dk:pt-2 tb:pt-1.5 dk:w-[574px] tb:w-[28rem] flex">
              <div className="flex w-4/12 flex-col gap-y-[0.4rem] ">
                <Typography
                  variant="textButton"
                  className="font-normal text-white"
                >
                  {footer?.contact?.phone}
                </Typography>
                <a href={phone?.href ?? ''}>
                  <Typography variant="textS" className="text-white/40">
                    {phone?.title}
                  </Typography>
                </a>
              </div>
              <div className="tb:w-[8.5rem] dk:w-4/12 dk:mr-[6px] flex flex-col gap-y-[0.4rem]">
                <Typography
                  variant="textButton"
                  className=" font-normal text-white"
                >
                  {footer?.contact?.email}
                </Typography>
                <a href={email?.href ?? ''}>
                  <Typography variant="textS" className="text-white/40">
                    {email?.title}
                  </Typography>
                </a>
              </div>
              <div className="flex w-4/12 flex-col gap-y-[0.4rem]">
                <Typography
                  variant="textButton"
                  className=" font-normal text-white"
                >
                  {footer?.contact?.address}
                </Typography>
                <address className="not-italic">
                  <Typography
                    variant="textS"
                    className="mb:static tb:absolute text-white/40"
                  >
                    {legalAddress?.address}
                  </Typography>
                </address>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {modalOpen && (
        <Modal
          title={footer?.modal?.title ?? ''}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        >
          <ModalContactForm
            setSubmitted={setSubmitted}
            setModalOpen={setModalOpen}
          />
        </Modal>
      )}
      <Snackbar setSubmitted={setSubmitted} submitted={submitted} />
    </>
  );
};
