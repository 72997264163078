import { Typography } from '@/shared/ui/typography';
import { overlayAnimation } from '@/widgets/header/Header';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useControlStyleByModal } from './useControlStyleByModal';

type Props = {
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  title?: string;
  children: React.ReactNode;
  titleClass?: string;
  modalClass?: string;
};

export const Modal: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
  title = null,
  titleClass,
  modalClass,
  children,
}) => {
  const { setStylesWhenClose, setStylesWhenOpen } = useControlStyleByModal();
  useEffect(() => {
    if (modalOpen) {
      setStylesWhenOpen();
    }

    return () => {
      if (document.body.style.overflow === 'hidden') {
        setStylesWhenClose();
      }
    };
  }, [modalOpen, setStylesWhenOpen, setStylesWhenClose]);

  return (
    <motion.div
      initial={false}
      animate={modalOpen ? 'open' : 'closed'}
      className="tb:gap-11 mb:gap-[25px] flex items-center gap-6"
    >
      <motion.div
        variants={overlayAnimation}
        className={
          'overlayModal !bg-dark/60 !z-[51] flex items-center justify-center overflow-hidden'
        }
        onClick={() => setModalOpen(false)}
      >
        <motion.div
          variants={overlayAnimation}
          onClick={(e) => e.stopPropagation()}
          className={cn(
            'tb:w-[577px] tb:min-h-[468px] mb:w-[345px] mb:min-h-[532px] mb:px-[48px] mb:py-[45px] relative z-[51] bg-white',
            modalClass,
          )}
        >
          {title && (
            <Typography
              variant="titleM"
              className={cn('mb:mb-[13px] text-black', titleClass)}
            >
              {title}
            </Typography>
          )}
          <div
            className="close before:bg-gray-1 after:bg-gray-1 !absolute right-[18px] top-[19px] cursor-pointer before:h-[13px] after:h-[13px] "
            onClick={() => setModalOpen(false)}
          ></div>
          {children}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
