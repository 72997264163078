import cn from 'classnames';
import { forwardRef } from 'react';

type ButtonProps = {
  isLeft?: boolean;
  mode?: 'dark' | 'light';
} & React.ComponentPropsWithRef<'button'>;

const defaultStyles = {
  dark: 'text-black hover:text-dark active:text-gray-3',
  light: 'text-white hover:text-white/80 active:text-gray-3',
};

const defaultDisabledStyles = {
  dark: 'text-gray-3 pointer-events-none',
  light: 'text-white/20 pointer-events-none',
};

export const SliderButton: React.FC<ButtonProps> = forwardRef(
  function ForwardedButton(
    { isLeft, mode = 'dark', disabled, className = '', ...props },
    ref,
  ) {
    return (
      <button
        className={cn(
          'h-[32px] w-[32px] cursor-pointer',
          defaultStyles[mode],
          { [defaultStyles[mode]]: disabled },
          { 'rotate-180': isLeft },
          className,
        )}
        disabled={disabled}
        {...props}
        ref={ref}
      >
        <svg
          className="ml-[13px]"
          width="7"
          height="12"
          fill="currentColor"
          viewBox="0 0 7 12"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.04547 2.16556e-07L-2.50171e-07 1.0522L4.91055 6.01689L0.0314551 10.9535L1.0713 12L7 6.015L1.04547 2.16556e-07Z"
          />
        </svg>
      </button>
    );
  },
);
