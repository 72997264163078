import { ButtonText } from '../../shared/ui/button';
import Cookies from 'js-cookie';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';

interface PropsSwitcher {
  isShowBoth?: boolean;
  className?: string;
}

interface PropsMultiSwitcher {
  router: NextRouter;
}

const LanguageMultiSwitcher = ({ router }: PropsMultiSwitcher) => {
  const { push, locale, locales, route } = router;
  const [activeLocal, setActiveLocale] = useState(locale === 'ru' ? 0 : 1);
  useEffect(() => {
    setActiveLocale(locale === 'ru' ? 0 : 1);
  }, [locale]);

  return (
    <div className="flex gap-[20px]">
      {locales?.map((locale, index) => (
        <ButtonText
          className={`${
            activeLocal === index ? '!text-white' : 'text-white/40'
          } hover:text-white`}
          isCapsVariant
          key={locale}
          locale={locale}
          onClick={() => {
            setActiveLocale(index);
            if (index === 0) {
              Cookies.remove('locale', { path: '/' });
            } else {
              Cookies.set('locale', 'en', { expires: 900 });
            }
          }}
        >
          {locale.toUpperCase()}
        </ButtonText>
      ))}
    </div>
  );
};

export const LanguageSwitcher = ({ isShowBoth, className }: PropsSwitcher) => {
  const router = useRouter();
  const { push, locale, locales, route } = router;

  const isRu = locale === 'ru';
  const strLocale = isRu ? locales![1] : locales![0];
  return (
    <>
      {!isShowBoth && (
        <ButtonText
          locale={strLocale}
          onClick={() => {
            strLocale === 'ru'
              ? Cookies.remove('locale', { path: '/' })
              : Cookies.set('locale', 'en', { expires: 900 });
          }}
          className={className}
        >
          {strLocale.charAt(0).toUpperCase() + strLocale.slice(1)}
        </ButtonText>
      )}
      {isShowBoth && <LanguageMultiSwitcher router={router} />}
    </>
  );
};
