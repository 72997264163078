import { Burger } from '../burger';
import { LanguageSwitcher } from '../languageSwitcher';
import { useBreakpoint } from '@/shared/hooks/useBreakpoints';
import { Navbar } from '@/shared/ui/navbar';
import { Phone } from '@/shared/ui/phone/Phone';
import { Variants, motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Search from '../search/Search';

interface Props {
  className: string;
}

export const overlayAnimation: Variants = {
  open: {
    opacity: 1,
    transition: { duration: 0.1 },
    pointerEvents: 'all',
  },
  closed: {
    opacity: 0,
    transition: { delay: 0.15 },
    pointerEvents: 'none',
  },
};

export const menuAnimation: Variants = {
  open: (custom) => ({
    x: `${custom * 100}%`,
    transition: { delay: 0.05, duration: 0.25, ease: 'easeOut' },
  }),
  closed: {
    x: '200%',
    transition: { duration: 0.25, ease: 'easeOut' },
  },
};

export const Header = ({ className }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setIsOpen((prev) => (prev ? false : true));
  };

  return (
    <motion.header
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, delay: 1, ease: 'easeInOut' }}
      className={`${className} mb: mb:pb-4 mb:pt-[14px] tb:py-6 dk:py-[40px] z-51 dk:mx-auto dk:my-0 container relative flex items-center justify-between`}
    >
      <div className="flex items-center gap-[57px]">
        <Link
          href={'/'}
          className="dk:h-[34px] dk:w-[170px] tb:h-[34px] tb:w-[170px] mb:h-[28px] mb:w-[136px]  relative cursor-pointer"
        >
          <Image src="/images/Logo.svg" alt="header-logo" fill />
        </Link>
        <div className="tb:max-dk:block text-gray-1 hidden cursor-pointer hover:text-black">
          <Search />
        </div>
      </div>
      <div className="dk:block dk:pl-[32px] hidden">
        <Navbar
          classNames={{
            navbar: 'dk:w-[29.5rem]',
            list: 'flex gap-10',
            listItem:
              'text-gray-2 hover:text-dark active:text-dark flex-auto-0',
          }}
          activeColor="#0D0D0E"
        />
      </div>
      <motion.div
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        className="tb:gap-11 mb:gap-[25px] flex items-center gap-6"
      >
        <div className="dk:pb-[0px] dk:ml-[-2px] tb:max-dk:hidden text-gray-1 block cursor-pointer pb-[1px] hover:text-black">
          <Search />
        </div>
        <div className="tb:hidden block">
          <Burger onClick={toggleOpen} />
        </div>
        <div className="dk:pt-[5px] tb:pt-[5px] tb:max-dk:gap-[26px] tb:flex hidden items-center gap-[42px]">
          <Phone />
          <LanguageSwitcher className="text-gray-1 hover:text-black" />
        </div>
        <div className="tb:max-dk:block hidden">
          <Burger onClick={toggleOpen} />
        </div>
        <motion.div
          variants={overlayAnimation}
          className={'overlayModal overflow-hidden'}
          onClick={toggleOpen}
        >
          <motion.div
            variants={menuAnimation}
            custom={0}
            className="dk:hidden bg-blue-additional mb:pb-[16px] mb:pt-[11px] mb:pl-[15px] mb:pr-[17px] tb:py-6 tb:pl-8 tb:pr-9 dk:px-12 dk:py-11 relative flex h-[100%] w-full justify-between"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex w-full flex-col justify-between ">
              {isOpen && (
                <>
                  <div className="flex w-full items-center justify-between">
                    <Link href={'/'} onClick={toggleOpen}>
                      <Image
                        src="/images/LogoWhite.svg"
                        width={isMobile ? 136 : 170}
                        height={isMobile ? 28 : 35}
                        alt="footer-logo"
                      />
                    </Link>
                    <div
                      className="close cursor-pointer"
                      onClick={toggleOpen}
                    ></div>
                  </div>
                  <div className="flex items-end justify-between">
                    <Navbar
                      onClick={toggleOpen}
                      classNames={{
                        list: 'flex flex-col tb:gap-[36px] gap-[1rem] mb:pl-[4px]',
                        listItem:
                          'text-gray-3 hover:text-white active:text-white',
                      }}
                      variant={isMobile ? 'titleL' : 'titleX'}
                      activeColor="white"
                    />
                    <div className="mb:max-tb:flex hidden flex-col items-end gap-[30px]">
                      <LanguageSwitcher className="text-white hover:text-white" />
                      <Phone className="text-white hover:text-white" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.header>
  );
};
