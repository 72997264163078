import { motion, AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const TransitionLayout = ({ children }: Props) => {
  const { asPath } = useRouter();

  return (
    <div className="effect-1">
      <AnimatePresence mode="wait">
        <motion.div
          key={asPath}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
