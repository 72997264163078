import { motion } from 'framer-motion';
import { useState } from 'react';

type Props = {
  onClick: () => void;
};

export const Burger: React.FC<Props> = ({ onClick }) => {
  const [color, setColor] = useState('bg-gray-2');
  return (
    <motion.div
      onHoverStart={() => setColor('bg-black')}
      onHoverEnd={() => setColor('bg-gray-2')}
      onClick={onClick}
      className="cursor-pointer"
    >
      <div className={`h-0.5 w-6 ${color}`}></div>
      <div className={`mt-[5px] h-0.5 w-6 ${color}`}></div>
      <div className={`mt-[5px] h-0.5 w-6 ${color}`}></div>
    </motion.div>
  );
};
