import { TransitionLayout, MainLayout } from '@/layouts';
import '@/shared/styles/_phoneInput.scss';
import '@/shared/styles/styles.css';
import '@/shared/styles/_brtags.scss';
import '@/shared/styles/_swiper.scss';
import '@/shared/styles/_catalog.scss';
import { wrapper } from '@/store';
import { appWithTranslation } from 'next-i18next';
import type { AppProps, AppInitialProps } from 'next/app';
import App from 'next/app';
import { Provider } from 'react-redux';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { store, props } = wrapper.useWrappedStore(pageProps);

  return (
    <Provider store={store}>
      <MainLayout className="">
        <TransitionLayout>
          <Component {...props} />
        </TransitionLayout>
      </MainLayout>
    </Provider>
  );
};

export default appWithTranslation(MyApp as any);

MyApp.getInitialProps = wrapper.getInitialAppProps(
  (store) =>
    async (ctx): Promise<AppInitialProps> => {
      const childrenGip = await App.getInitialProps(ctx);
      return {
        pageProps: {
          ...childrenGip,
        },
      };
    },
);
