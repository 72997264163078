export const defaultTextTransition = {
  duration: 1,
  ease: 'easeInOut',
};

export const defaultTextTransform = {
  duration: 0.7,
  ease: 'easeInOut',
};

export const defaultTextDisclosure = {
  duration: 1,
  ease: 'easeInOut',
};

export const simpleTextAnimationWhileView = {
  initial: { clipPath: 'inset(0 100% 0 0)' },
  whileInView: { clipPath: 'inset(0 0 0 0)' },
  transition: defaultTextTransition,
  viewport: { once: true },
};
