import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { StrapiResponse, Layout, StrapiQuery } from '~shared';
import * as qs from 'qs';
import { useRouter } from 'next/router';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_API_URL}/api`,
    //                                                    //
    // WARNING THIS OVERIDE REMOVES ENGLISH  locale: 'ru' //
    // __________________________________________________ //
    paramsSerializer: (params: StrapiQuery) =>
      qs.stringify({ ...params }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      // @ts-expect-error too complex type
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    layout: builder.query<Layout, StrapiQuery | undefined>({
      query: (params) => ({
        url: '/layout',
        params,
      }),
      transformResponse: ({ data }: StrapiResponse<Layout>) => data,
    }),
  }),
});

export const { useLayoutQuery } = api;

export const useLayout = () => {
  const { locale, isFallback } = useRouter();
  return useLayoutQuery(
    { locale },
    { skip: isFallback, selectFromResult: ({ data }) => ({ ...data }) },
  );
};
