import { useRouter } from 'next/router';
import { Error, StrapiQuery, StrapiResponse } from '~shared';
import { api } from './api';

export const errorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    error: builder.query<Error, StrapiQuery | undefined>({
      query: (params) => ({
        url: '/error',
        params,
      }),
      transformResponse: ({ data }: StrapiResponse<Error>) => data,
    }),
  }),
});

export const { useErrorQuery } = errorApi;

export const useError = () => {
  const { locale, isFallback } = useRouter();
  return useErrorQuery(
    { locale },
    { skip: isFallback, selectFromResult: ({ data }) => ({ ...data }) },
  );
};
