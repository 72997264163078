import cn from 'classnames';
import Image from 'next/image';
import { ChangeEvent, useState } from 'react';

type CheckboxProps = {
  policyChecked: boolean;
  setPolicyChecked: (arg: boolean) => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  policyChecked,
  setPolicyChecked,
}) => {
  // const [checked, setChecked] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target) {
      setPolicyChecked(event.target.checked);
    }
  };

  return (
    <div className="relative w-fit">
      <input
        onChange={handleChange}
        aria-label="privacy agreement"
        checked={policyChecked}
        className={cn(
          'w-4 h-4 border border-black appearance-none checked:bg-dark',
          'tb:hover:border-gray-1',
          'disabled:border-gray-3',
          { 'tb:hover:bg-gray-1 disabled:bg-gray-3': policyChecked },
        )}
        type="checkbox"
      />
      <div
        className={cn(
          'absolute w-[10px] h-[7.6px] z-10 top-[4.5px] left-[3.5px] pointer-events-none',
          { hidden: !policyChecked },
        )}
      >
        <Image src="/images/checkmark.svg" alt="checkmark" fill />
      </div>
    </div>
  );
};
