import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import Mark from "mark.js";

interface Props {
  children: React.ReactNode;
  className: string;
}

export const MainLayout = ({ children, className }: Props) => {
  const { query: { search } } = useRouter();
  const markInstance = useRef<any>();

  useEffect(() => {
    markInstance.current = new Mark(document.querySelector("#main"));
  }, [])

  useEffect(() => {
    if (search) {
      markInstance.current.mark(search);
    }
  });

  return (
    <div id="main">
      <Header className={className} />
      <main className={`${className} text-black`}>{children}</main>
      <Footer className={className} />
    </div>
  );
};
