import { SearchMeta, SearchResult, StrapiResponse } from '~shared';
import { api } from './api';

export const searchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<
      StrapiResponse<SearchResult[], SearchMeta>,
      { term: string | undefined; total?: number; locale?: string }
    >({
      query: ({ term, ...params }) => ({
        url: '/search',
        params: { term: term?.toLowerCase(), ...params },
      }),
    }),
  }),
});

export const { useSearchQuery } = searchApi;
