import { Typography } from '@/shared/ui/typography';
import { overlayAnimation } from '@/widgets/header/Header';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

type Props = {
  submitted: boolean;
  setSubmitted: (arg: boolean) => void;
};

export const Snackbar: React.FC<Props> = ({ submitted, setSubmitted }) => {
  useEffect(() => {
    const timeout = setTimeout(() => setSubmitted(false), 6000);
    return () => {
      clearTimeout(timeout);
    };
  }, [submitted, setSubmitted]);
  return (
    <>
      {submitted && (
        <motion.div className="snackbar fixed bottom-[54px] z-[51] left-[50%] ml-[-172.5px] flex justify-center items-center w-[345px] h-[52px] bg-gray-5">
          <Typography variant="textM" className="text-black select-none">
            Сообщение отправлено
          </Typography>
        </motion.div>
      )}
    </>
  );
};
