import { Typography } from '../typography';
import cn from 'classnames';
import Image from 'next/image';
import { forwardRef } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { useLayout } from '@/store/api';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
  setError?: UseFormSetError<any>;
  fileName: string;
}

export const FileInput = forwardRef<any, Props>(function ForwardInput(
  { errorMessage, disabled, setError, id, fileName, ...other },
  ref,
) {
  const { forms } = useLayout();
  const { i18n } = useTranslation();
  return (
    <div className="relative">
      <label
        htmlFor={id}
        className="relative flex w-[100%] cursor-pointer items-center"
      >
        <span className="relative h-9 w-7">
          <Image alt="clip" src="/images/clip.svg" className="relative" fill />
        </span>
        <span className=" text-textM mb:max-tb:ml-[3px] text-black">
          {fileName
            ? i18n.language === 'en'
              ? `ATTACHED ${fileName}`
              : `ПРИКРЕПЛЕНО: ${fileName}`
            : forms?.cv?.file}
        </span>
        <input
          className="hidden"
          type="file"
          ref={ref}
          id={id}
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          {...other}
        />
      </label>
      {errorMessage && (
        <Typography
          variant="textS"
          className={cn(
            'mt-1',
            {
              'text-gray-3': !disabled,
            },
            {
              'text-gray-4': disabled,
            },
            { 'text-red': !!errorMessage },
          )}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
});
